
import React from "react"
import "./main.css"
import appstore from "../../../static/appstore.png"
import google from "../../../static/googleplay.png"

export default function Main({params}) {




    return <main>
        <div className="header-container">
            <h1>Mynt App downloads</h1>

        </div>
        <div className="download-container">
            <a href={"https://apps.apple.com/fi/app/pepi/id1620118453/"+params}><img className="appstores" src={appstore} /></a>
            <a href={"https://play.google.com/store/apps/details?id=com.sterly.pepirelease&"+params}><img className="appstores" src={google} /></a>
        </div>

        <div className="footer">
            MyntApp.io
        </div>
    </main>
}
