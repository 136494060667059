
import React from "react"
import Main from "./redirectview/main";

export default function Home(props) {

  let params =props?.location?.search 



  function getMobileOperatingSystem() {
    var userAgent = typeof window !== 'undefined' && (navigator.userAgent || navigator.vendor || window.opera);

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      window.location.replace("https://play.google.com/store/apps/details?id=com.sterly.pepirelease&"+params);
      return false;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.replace("https://apps.apple.com/fi/app/pepi/id1620118453/"+params);
      return false;
    }

    return true;
  }
  let operating = getMobileOperatingSystem()

  if(!operating) {
    return null
  }
  return <Main params={params}/>
}
